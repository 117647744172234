




































































































































































import Vue from "vue";
import CoursesNumbers from "@/components/candidate/courses/CoursesNumbers.vue";
import { get_random_color } from "@/utils/colors";
import CoursesSlider from "@/components/candidate/courses/CoursesSlider.vue";

export default Vue.extend({
  name: "CandidateCourses",
  data() {
    return {
      courses_dropdown_items: [
        {
          name: "Complete web design",
          avatar: require("@/assets/images/course.png")
        }
      ],
      date_time_dropdown_items: ["Weekly"],
      courses_dropdown_selection: {
        name: "Complete web design",
        avatar: require("@/assets/images/course.png")
      },
      date_time_dropdown_selection: "Weekly"
    };
  },
  methods: { get_random_color },
  components: { CoursesSlider, CoursesNumbers }
});
