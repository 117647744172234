






















































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_JOBS_LOADING,
  GET_MATCHING_JOBS,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/candidates/constants";
import { USER_PROFILE } from "@/store/modules/auth/constants";
import { CoursesSlider } from "@/interfaces/candidate/candidate_courses/courses_slider";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import IndividualCourse from "@/components/candidate/courses/IndividualCourse.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CoursesSlider",
  components: { IndividualCourse, DataNotFound },
  data(): CoursesSlider {
    return {
      gap_list: [],
      response_id: "",
      job_id: "",
      data_found: false,
      local_upskill_courses: []
    };
  },
  async created() {
    if (!this.upskill_courses) {
      this.set_upskill_courses_loading(true);
      this.response_id = this.user_profile.info.file_id;
      if (this.matching_jobs.length > 0 && !this.jobs_loading) {
        this.job_id = this.matching_jobs[0].job_id;
        this.gap_list = this.matching_jobs[0].job_gap;
        this.data_found = true;
      } else if (!this.jobs_loading) {
        await this.fetch_matching_jobs(15);
        if (this.matching_jobs.length > 0) {
          this.job_id = this.matching_jobs[0].job_id;
          this.gap_list = this.matching_jobs[0].job_gap;
          this.data_found = true;
        } else
          this.root_error(`${this.$t("shared.no-matching-job").toString()}`);
      } else if (this.jobs_loading) {
        // console.log("IN progress matching jobs");
      } else {
        this.set_upskill_courses_loading(false);
        this.root_error(`${this.$t("shared.no-matching-job").toString()}`);
      }
      if (this.data_found) {
        await this.get_upskill_courses();
      }
    } else if (this.upskill_courses) this.process_upskilling_data();
  },
  watch: {
    jobs_loading(new_value) {
      if (!new_value && this.matching_jobs.length <= 0 && !this.data_found) {
        this.set_upskill_courses_loading(false);
        this.root_error("Matching Jobs Not Found");
      }
    },
    matching_jobs(new_value) {
      if (!this.data_found) {
        if (new_value.length > 0) {
          this.job_id = new_value[0].job_id;
          this.gap_list = new_value[0].job_gap;
          this.data_found = true;
          this.get_upskill_courses();
        }
      }
    },
    upskill_courses(new_value) {
      if (new_value) this.process_upskilling_data();
    }
  },
  computed: {
    ...mapGetters("candidate", {
      upskill_courses: UPSKILL_COURSES,
      matching_jobs: GET_MATCHING_JOBS,
      jobs_loading: GET_JOBS_LOADING,
      upskill_courses_loading: UPSKILL_COURSES_LOADING
    }),
    ...mapGetters("auth", {
      user_profile: USER_PROFILE
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapMutations("candidate", {
      set_upskill_courses_loading: UPSKILL_COURSES_LOADING
    }),
    ...mapActions("candidate", {
      fetch_upskill_courses: UPSKILL_COURSES,
      fetch_matching_jobs: GET_MATCHING_JOBS
    }),
    async get_upskill_courses() {
      const payload = {
        response_id: this.response_id,
        job_id: this.job_id.toString(),
        gap_list: this.gap_list
      };
      await this.fetch_upskill_courses(payload);
      this.set_upskill_courses_loading(false);
    },
    process_upskilling_data() {
      const upskill_data = this.upskill_courses;
      this.local_upskill_courses = upskill_data.upskill;
    }
  }
});
